import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/utilities/services/common.service';
import { MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomFormService {

  constructor(private http: HttpClient, private commonService: CommonService, private matSnackBar: MatSnackBar) { }
  getAllForms(payload: Object) {
    let access_token = 'Bearer ' + localStorage.getItem('accessToken');
    let url = this.commonService.generateGetUrl(`${environment.api_url}/form/getAllForms`, payload);
    return this.http.get(url, { headers: { 'authorization': access_token, 'content-language': 'en' } })
      .pipe(map((res: Response) => {
        if (res['statusCode'] === 200) { return res }
        else {
          if (res.status === 401) {
            this.commonService.handleSession();
          }
        }
      }))
  }
  getFormById(payload: Object) {
    let access_token = 'Bearer ' + localStorage.getItem('accessToken');
    let url = this.commonService.generateGetUrl(`${environment.api_url}/form/getFormById`, payload);
    return this.http.get(url, { headers: { 'authorization': access_token, 'content-language': 'en' } })
      .pipe(map((res: Response) => {
        if (res['statusCode'] === 200) { return res }
        else {
          if (res.status === 401) {
            this.commonService.handleSession();
          }
        }
      }))
  }

  addForm(payload: Object) {
    let access_token = 'Bearer ' + localStorage.getItem('accessToken');
    return this.http.post(`${environment.api_url}/form/addForm`, payload, { headers: { 'authorization': access_token, 'content-language': 'en' } })
      .pipe(map((res: any) => {
        if (res.statusCode === 200) { return res } else {
          console.error(res); this.matSnackBar.open('Something went wrong', 'Error', {
            duration: 2000,
          }); return;
        }
      }))
  }
  getFormByAdmin(payload: Object) {
    let access_token = 'Bearer ' + localStorage.getItem('accessToken');
    return this.http.post(`${environment.api_url}/form/getFormsByAdmin`, payload, { headers: { 'authorization': access_token, 'content-language': 'en' } })
      .pipe(map((res: any) => {
        if (res.statusCode === 200) { return res } else {
          console.error(res); this.matSnackBar.open('Something went wrong', 'Error', {
            duration: 2000,
          }); return;
        }
      }))
  }
  editForm(payload: Object) {
    let access_token = 'Bearer ' + localStorage.getItem('accessToken');
    return this.http.post(`${environment.api_url}/form/updateFormById`, payload, { headers: { 'authorization': access_token, 'content-language': 'en' } })
      .pipe(map((res: any) => {
        if (res.statusCode === 200) { return res } else {
          console.error(res); this.matSnackBar.open('Something went wrong', 'Error', {
            duration: 2000,
          }); return;
        }
      }))
  }
  deleteForm(payload) {
    const access_token = 'Bearer ' + localStorage.getItem('accessToken');
    return this.http.request('delete', `${environment.api_url}/form/deleteFormById`, {
      headers:
      {
        'authorization': access_token,
        'content-language': 'en',
        'utcoffset': (new Date().getTimezoneOffset()).toString()
      }, body: payload
    }).pipe(map((res: any) => {
        if (res.statusCode === 200) { return res; } else {
          console.error(res); this.matSnackBar.open('Something went wrong', 'Error', {
            duration: 2000,
          }); return;
        }
      })
      );
  }
}
