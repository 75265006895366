export const TemplateDataTypes = {
    'text': {
        name: 'Text',
        id: 0
    },
    'email': {
        name: 'Email',
        id: 1
    },
    'number': {
        name: 'Number',
        id: 2
    },
    'date': {
        name: 'Date',
        id: 3
    },
    'date_past': {
        name: 'Date-Past',
        id: 4
    },
    'checkbox': {
        name: 'Checkbox',
        id: 5
    },
    'dropdown': {
        name: 'Dropdown',
        id: 6
    },
    'telephone': {
        name: 'Telephone',
        id: 7
    },
    'image': {
        name: 'Image',
        id: 8
    },
    'stext': {
        name: 'sText',
        id: 10
    },
    'scheckbox': {
        name: 'sCheckbox',
        id: 11
    },
    'sdropdown': {
        name: 'sDropdown',
        id: 12
    }
}

export const Form_Request_Type = {
    GET_ADMIN_SIDE_VISIBLE_FORMS: 0,
    GET_CUSTOMER_SIDE_VISIBLE_FORMS: 1,
    GET_ALL_FORMS: 2,
    GET_ALL_FORMS_NEW: 7,  
    GET_CHECKIN_COMPLETED_FORMS: 3
}
export const Checkin_Status = {
    PENDING : 0,
    REJECTED : 1, // send back to customer
    SUCCESS : 2 // means send to esign-ginee
}